import { useEffect, useState, useContext, useMemo } from "react";
import "./style.scss";
import { mapDispatchToProps, mapStateToProps } from "../../../store/dispatcher";
import { DispatchProps, StateProps } from "../../../store";
import { connect } from "react-redux";
import AuthContext from "../../../contexts/AuthContext";

import axios from "../../../services/axios";
import { useParams } from "react-router-dom";
import { Segmented, Skeleton, Tooltip, Switch, Button } from "antd";
import {
  DatabaseOutlined,
  InfoCircleOutlined,
  LineChartOutlined,
} from "@ant-design/icons";
import {
  Cell,
  Pie,
  PieChart,
  ResponsiveContainer,
} from "recharts";
import TimeSelector, { generate_period } from "./time_selector/TimeSelector";
import { useTranslation } from "react-i18next";
import GraphLoader from "./graph_loader/GraphLoader";
import { Device } from "../../../lib/Device";
import { darkenHexColor } from "../../../helpers/color";
import PowerLineChart from "./line_chart/PowerLineChart";
import TensionLineChart from "./line_chart/TensionLineChart";
import TemperatureLineChart from "./line_chart/TemperatureLineChart";
import { timestampToReadable, downRounding, upRounding } from "./line_chart/LineChartCommon";
import { Home } from "../../../lib/Home";
import DropoutLineChart from "./line_chart/DropoutLineChart";

const generate_line_data = (data: any, currentDevice: Device) => {
  const tempIdxToTemp: any = {
    0: currentDevice.t_cold,
    1: currentDevice.t_lukewarm,
    2: currentDevice.t_hot,
    3: currentDevice.t_very_hot
  }
  const chart_data = data.map((entry: any) => {
    const entryDate = new Date(entry.timestamp * 1000)
    const entryDay = entryDate.getDay()
    const dayIdx = entryDay > 0 ? entryDay - 1 : 6
    const hourIdx = entryDate.getHours()
    const tempIdx = currentDevice.schedule[dayIdx][hourIdx]
    return {
      time: entry.timestamp,
      power_balance: entry.power_balance,
      power_balance_no_soliseco: entry.power_balance_no_soliseco,
      soliseco_power: entry.soliseco_power,
      u_1: entry.u_1,
      u_2: entry.u_2,
      u_3: entry.u_3,
      water_temperature: entry.water_temperature,
      dropout_v_max: currentDevice.dropout_v_max,
      min_planning_temp: tempIdxToTemp[tempIdx],
    };
  });
  return chart_data;
};

const generate_pie_data = (data: any, multiDeviceHome: boolean) => {

  const exported_energy_values = [
      {
        name: "real_export",
        value: parseFloat(data.energy_export.toFixed(2)),
        color: "#D89537",
      },
      {
        name: "device_solar_energy_export",
        value: parseFloat(data.solar_energy_soliseco.self.toFixed(2)),
        color: "#72A363",
      }
  ]
  if (multiDeviceHome === true) {
    exported_energy_values.push({
      name: "other_solar_energy_export",
      value: parseFloat(data.solar_energy_soliseco.others.toFixed(2)),
      color: "#3b6bb8",
    })
  }
  const exported_energy = {
    total: (
      data.energy_export +
      data.solar_energy_soliseco.self +
      data.solar_energy_soliseco.others
    ).toFixed(2),
    values: exported_energy_values
  };

  const imported_energy_values = [
    {
      name: "other_import",
      value: parseFloat((data.energy_import - (data.non_solar_energy_soliseco.self + data.non_solar_energy_soliseco.others)).toFixed(2)),
      color: "#a63d50",
    },
    {
      name: "device_import",
      value: parseFloat(data.non_solar_energy_soliseco.self.toFixed(2)),
      color: "#041a1c",
    }
  ]
  if (multiDeviceHome === true) {
    imported_energy_values.push({
      name: "other_device_import",
      value: parseFloat(data.non_solar_energy_soliseco.others.toFixed(2)),
      color: "#3b6bb8",
    })
  }
  const imported_energy = {
    total: data.energy_import.toFixed(2),
    values: imported_energy_values
  };

  const hot_water_production = {
    total: (
      data.solar_energy_soliseco.self + data.non_solar_energy_soliseco.self
    ).toFixed(2),
    values: [
      {
        name: "water_production_legend_1",
        value: parseFloat(data.solar_energy_soliseco.self.toFixed(2)),
        color: "#72A363",
      },
      {
        name: "water_production_legend_2",
        value: parseFloat(data.non_solar_energy_soliseco.self.toFixed(2)),
        color: "#041a1c",
      },
    ],
  };

  return { exported_energy, imported_energy, hot_water_production };
};

const RADIAN = Math.PI / 180;

interface LabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  value: number;
  fill: string;
  index: number;
}

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  value,
  fill,
  index,
}: LabelProps) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + (radius + 25) * Math.cos(-midAngle * RADIAN);
  const y = cy + (radius + 25) * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill={fill}
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${value} kWh`}
    </text>
  );
};

const TemperatureCell = ({
  device,
  temperature,
}: {
  device: any;
  temperature: number;
}) => {
  const { t } = useTranslation("settings");

  const color = {
    cold: "#D1E6FF",
    warm: "#FCECCD",
    hot: "#F9DBBF",
    very_hot: "#FCCDCD",
  };

  const temp_to_show: "cold" | "warm" | "hot" | "very_hot" = useMemo(() => {
    if (device) {
      if (temperature < device.t_cold) {
        return "cold";
      } else if (temperature < device.t_lukewarm) {
        return "warm";
      } else if (temperature < device.t_hot) {
        return "hot";
      } else {
        return "very_hot";
      }
    }
    return "cold"
  }, [temperature, device])

  return (
    <Tooltip title={temperature.toFixed(1) + "°C"}>
      <span
        className="value cell"
        style={{
          color: darkenHexColor(color[temp_to_show]),
          backgroundColor: color[temp_to_show],
        }}
      >
        {t(temp_to_show)}
      </span>
    </Tooltip>
  );
};

const DeviceDashboard = ({ devices, homes }: { devices: Device[], homes: Home[] }) => {
  const { device_id, home_id } = useParams();
  const { t } = useTranslation("dashboard");

  const [lastData, setLastData] = useState<any>(null);
  const [lineData, setLineData] = useState<any>(null);
  const [pieData, setPieData] = useState<any>(null);

  const [focusMode, setFocusMode] = useState<boolean>(true);
  const [lastDataSegment, setLastDataSegment] = useState<string>("SOLISECO")
  const [pieDataPeriod, setPieDataPeriod] = useState<any>(generate_period(new Date(), "now"))
  const [lineDataSegment, setLineDataSegment] = useState<string>("POWERS")
  const [lineDataPeriod, setLineDataPeriod] = useState<any>(generate_period(new Date(), "now"))

  const { user } = useContext(AuthContext);

  const currentDevice = devices?.find(
    (device) => device.id === parseInt(device_id ? device_id : "0")
  );

  const currentHome = homes?.find(
    (home) => home.id === parseInt(home_id ? home_id : "0")
  );

  const multiDeviceHome: boolean = useMemo(() => {
    if (home_id && devices)
      return devices.filter((device) => device.home === parseInt(home_id)).length > 1
    return false
  }, [devices, home_id])

  const lineChartXTickFormatter = (val: string, idx: number) => {
    const formattedDate = timestampToReadable(parseInt(val))
    if (lineDataPeriod && lineDataPeriod.start === lineDataPeriod.end) {
      return formattedDate.split(" ")[1];
    }
    return formattedDate;
  };

  const ticks = useMemo(() => {
    if (lineData) {
      if (lineData.length > 1) {
        const start = lineData[0].time
        const end = lineData[lineData.length - 1].time
        const roundedStart = upRounding(start, 900)
        const roundedEnd = downRounding(end, 900)
        const diff = roundedEnd - roundedStart
        if (diff > 3600) {
          const secondTick = downRounding(roundedStart + (diff / 3), 900)
          const thirdTick = downRounding(roundedStart + ((2 * diff) / 3), 900)
          return [roundedStart, secondTick, thirdTick, roundedEnd]

        }
        if (roundedEnd - roundedStart >= 900) {
          return [roundedStart, roundedEnd]
        }
      }
      return lineData.map((elem: any) => elem.time)
    }
    return []
  }, [lineData])

  const update_pie_data = async (period: any, multiDeviceHome: boolean) => {
    await axios
      .get(
        `/api-v1/device/${device_id}/energy?start=${period.start}&end=${period.end}`
      )
      .then((resp) => {
        setPieData(generate_pie_data(resp.data, multiDeviceHome));
      })
      .catch((e) => setPieData(null));
  };

  const update_line_data = async (period: any, currentDevice: Device) => {
    await axios
      .get(
        `/api-v1/device/${device_id}/time-data?start=${period.start}&end=${period.end}`
      )
      .then((resp) => {
        setLineData(generate_line_data(resp.data, currentDevice));
      })
      .catch((e) => setLineData([]));
  };

  const update_last_data = async (device_id: string) => {
    await axios
      .get(`/api-v1/device/${device_id}/last`)
      .then((resp) => {
        setLastData(resp.data);
      })
      .catch((e) => setLastData({}));
  };

  const refetch = () => {
    if (device_id && currentDevice) {
      update_last_data(device_id);
      update_pie_data(pieDataPeriod, multiDeviceHome);
      update_line_data(lineDataPeriod, currentDevice);
    }
  };

  const isFocus = useMemo(() => {
    return user.is_login && document.visibilityState === "visible"
  }, [user.is_login, document.visibilityState])

  const focus = (e: any) => {
    if (isFocus) {
      refetch();
    }
  };

  useEffect(() => {
    window.addEventListener("visibilitychange", focus);
    return () => {
      window.removeEventListener("visibilitychange", focus);
    };
  }, [device_id, currentHome, pieDataPeriod, lineDataPeriod]);

  useEffect(() => {
    if (device_id && isFocus) {
      update_last_data(device_id);
      const intervalId = setInterval(
        () => update_last_data(device_id),
        1000 * 120
      );
      return () => clearInterval(intervalId);
    }
  }, [device_id, isFocus]);

  useEffect(() => {
    if (device_id && isFocus) {
      update_pie_data(pieDataPeriod, multiDeviceHome);
      const intervalId = setInterval(
        () => update_pie_data(pieDataPeriod, multiDeviceHome),
        1000 * 120
      );
      return () => clearInterval(intervalId);
    }
  }, [device_id, pieDataPeriod, multiDeviceHome, isFocus]);

  useEffect(() => {
    if (currentDevice && device_id && isFocus) {
      update_line_data(lineDataPeriod, currentDevice);
      const intervalId = setInterval(
        () => update_line_data(lineDataPeriod, currentDevice),
        1000 * 120
      );
      return () => clearInterval(intervalId);
    }
  }, [device_id, currentDevice, lineDataPeriod, isFocus]);

  return (
    <div className="device-dashboard">
      <div className="pie-container">
        <div className="pies">
          <div className="pie-block">
            <span className="pie-title">
              {t("exported_energy_title")}{" "}
              <Tooltip title={t("exported_energy_tooltip")}>
                <InfoCircleOutlined
                  style={{ color: "#012124", opacity: 0.4 }}
                />
              </Tooltip>
            </span>
            {pieData != null ? (
              <>
                <div className="chart-container">
                  <div className="absolute-center-title">
                    <span className="title">
                      {pieData.exported_energy.total}kWh
                    </span>
                    <span>{t("exported_energy_center_data")}</span>
                  </div>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={pieData.exported_energy.values}
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        paddingAngle={5}
                        dataKey="value"
                        label={renderCustomizedLabel}
                      >
                        {pieData.exported_energy.values.map(
                          (entry: any, index: number) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                          )
                        )}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <div className="legend-container">
                  {pieData.exported_energy.values.map(
                    (entry: any, index: number) => (
                      <div className="legend-item" key={index}>
                        <span
                          className="color-block"
                          style={{ backgroundColor: entry.color }}
                        ></span>
                        <span>
                          {t(entry.name, { device_name: currentDevice?.name })}
                        </span>
                      </div>
                    )
                  )}
                </div>
              </>
            ) : (
              <GraphLoader shape="circle" />
            )}
          </div>
          <div className="pie-block">
            <span className="pie-title">
              {t("imported_energy_title")}{" "}
              <Tooltip title={t("imported_energy_tooltip")}>
                <InfoCircleOutlined
                  style={{ color: "#012124", opacity: 0.4 }}
                />
              </Tooltip>
            </span>
            {pieData != null ? (
              <>
                <div className="chart-container">
                  <div className="absolute-center-title">
                    <span className="title">
                      {pieData.imported_energy.total}kWh
                    </span>
                    <span>{t("imported_energy_center_data")}</span>
                  </div>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={pieData.imported_energy.values}
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        paddingAngle={5}
                        dataKey="value"
                        label={renderCustomizedLabel}
                      >
                        {pieData.imported_energy.values.map(
                          (entry: any, index: number) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                          )
                        )}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <div className="legend-container">
                  {pieData.imported_energy.values.map(
                    (entry: any, index: number) => (
                      <div className="legend-item" key={index}>
                        <span
                          className="color-block"
                          style={{ backgroundColor: entry.color }}
                        ></span>
                        <span>
                          {t(entry.name, { device_name: currentDevice?.name })}
                        </span>
                      </div>
                    )
                  )}
                </div>
              </>
            ) : (
              <GraphLoader shape="circle" />
            )}
          </div>
          <div className="pie-block">
            <span className="pie-title">
              {t("water_production_title")}{" "}
              <Tooltip title={t("water_production_tooltip")}>
                <InfoCircleOutlined
                  style={{ color: "#012124", opacity: 0.4 }}
                />
              </Tooltip>
            </span>
            {pieData != null ? (
              <>
                <div className="chart-container">
                  <div className="absolute-center-title">
                    <span className="title">
                      {pieData.hot_water_production.total}kWh
                    </span>
                    <span>{t("water_production_center_data")}</span>
                  </div>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={pieData.hot_water_production.values}
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        paddingAngle={5}
                        dataKey="value"
                        label={renderCustomizedLabel}
                      >
                        {pieData.hot_water_production.values.map(
                          (entry: any, index: number) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                          )
                        )}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                </div>
                <div className="legend-container">
                  {pieData.hot_water_production.values.map(
                    (entry: any, index: number) => (
                      <div className="legend-item" key={index}>
                        <span
                          className="color-block"
                          style={{ backgroundColor: entry.color }}
                        ></span>
                        <span>
                          {t(entry.name, { device_name: currentDevice?.name })}
                        </span>
                      </div>
                    )
                  )}
                </div>
              </>
            ) : (
              <GraphLoader shape="circle" />
            )}
          </div>
        </div>
        <TimeSelector set_period={setPieDataPeriod} />
      </div>
      <div className="row">
        <div className="chart-title-block">
          <span className="title">
            {t("last_info_title")}
            <Tooltip title={t("last_info_title")}>
              <InfoCircleOutlined style={{ color: "#012124", opacity: 0.4 }} />
            </Tooltip>
          </span>

          <div
            className={`chart-block data ${
              lastData != null && Object.keys(lastData).length === 0
                ? "empty"
                : ""
            }`}
          >
            {lastData != null &&
              (Object.keys(lastData).length === 0 ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    margin: "auto",
                    width: "100%",
                  }}
                >
                  <DatabaseOutlined
                    style={{ color: "#012124", opacity: 0.6, fontSize: 80 }}
                  />
                  <p>No Data</p>
                </div>
              ) : (
                <>
                  <Segmented
                    options={[
                      {
                        label: t("last_info_choice_soliseco"),
                        value: "SOLISECO",
                      },
                      { label: t("last_info_choice_meter"), value: "COMPTEUR" },
                    ]}
                    value={lastDataSegment}
                    onChange={setLastDataSegment}
                    style={{ width: "fit-content", marginInline: "auto" }}
                  />
                  {
                    lastDataSegment === "COMPTEUR" && (
                      <>
                        <div className="data-item">
                          <span className="label">{t("last_info_p_tot")}</span>
                          <span className="value">
                            {lastData.p_tot} W
                          </span>
                        </div>
                        <div className="data-item">
                          <span className="label">{t("last_info_u1")}</span>
                          <span className="value">{lastData.u_1} V</span>
                        </div>
                        <div className="data-item">
                          <span className="label">{t("last_info_u2")}</span>
                          <span className="value">{lastData.u_2} V</span>
                        </div>
                        <div className="data-item">
                          <span className="label">{t("last_info_u3")}</span>
                          <span className="value">{lastData.u_3} V</span>
                        </div>
                        <div className="data-item">
                          <span className="label">{t("last_info_peak")}</span>
                          <span className="value">
                            {lastData.power_monthly_peak} W
                          </span>
                        </div>
                        <div className="data-item">
                          <span className="label">{t("last_info_avg")}</span>
                          <span className="value">
                            {lastData.power_avg_15_m} W
                          </span>
                        </div>
                        <div className="data-item">
                          <span className="label">{t("last_info_dropout_threshold_counter")}</span>
                          <span className="value">
                            {lastData.dropout_threshold_counter}
                          </span>
                        </div>
                      </>
                    )
                  }
                  {
                    lastDataSegment === "SOLISECO" && (
                      <>
                        <div className="data-item">
                          <span className="label">{t("last_info_power")}</span>
                          <span className="value">
                            {lastData.power_soliseco} W
                          </span>
                        </div>
                        <div className="data-item">
                          <span className="label">
                            {t("last_info_power_percentage")}
                          </span>
                          <span className="value">
                            {lastData.heating_percentage_power} %
                          </span>
                        </div>
                        <div className="data-item">
                          <span className="label">{t("last_info_current_temp")}</span>
                          <TemperatureCell
                            device={currentDevice}
                            temperature={lastData.water_temperature}
                          />
                        </div>
                        {/* <div className="data-item">
                          <span className="label">
                            {t("last_info_last_sanitation")}
                          </span>
                          <span className="value">
                            {display_date_locale(
                              lastData.last_sanitation * 1000,
                              t("js_local_date")
                            )}
                          </span>
                        </div> */}
                        <div className="data-item">
                          <span className="label">{t("last_info_status")}</span>
                          <span
                            className="value cell"
                            style={{ textTransform: "lowercase" }}
                          >
                            {lastData.running_state}
                          </span>
                        </div>
                      </>
                    )
                  }

                </>
              )) }
            <div style={ { display: "flex", justifyContent: "center", alignItems: "flex-end", height: "100%" } }>
              <Button
                href={ `http://${currentDevice?.own_address_ip}` }
                target="_blank"
              >
                { t("live_result_visualisation")}
              </Button>
            </div>
          </div>
        </div>
        <div className="chart-title-block large">
          <span className="title">
            {t("time_data_title")}
            <Tooltip title={t("time_data_tooltip")}>
              <InfoCircleOutlined style={{ color: "#012124", opacity: 0.4 }} />
            </Tooltip>
          </span>
          <div className="chart-block center">
            <div className="line-chart-header" >
              { lineDataSegment === "POWERS" && <div style={ { width: 61.2 } } /> }
              <Segmented
                options={ [
                  { label: t("time_data_choice_powers"), value: "POWERS" },
                  { label: t("time_data_choice_tensions"), value: "TENSIONS" },
                  { label: t("time_data_choice_temperature"), value: "TEMPERATURE" },
                  { label: t("time_data_choice_dropout"), value: "DROPOUT", disabled: !currentDevice?.handle_dropout },
                ] }
                value={ lineDataSegment }
                onChange={ setLineDataSegment }
                style={{width: 'fit-content', marginInline: 'auto'}}
              />
              { lineDataSegment === "POWERS" && <Switch checked={ focusMode } onClick={ (checked) => setFocusMode(checked) } checkedChildren="focus" unCheckedChildren="full" /> }
            </div>

            {lineData == null ? (
              <Skeleton.Avatar
                active
                className="line-skeleton"
                shape="square"
              />
            ) : (
                lineData.length === 0 ? (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "column",
                      justifyContent: "center",
                      width: "100%",
                      height: "100%",
                    }}
                  >
                    <LineChartOutlined
                      style={{ color: "#012124", opacity: 0.6, fontSize: 80 }}
                    />
                    <p>No Data</p>
                  </div>
                ) : (
                  <>
                    { lineDataSegment === 'POWERS' && <PowerLineChart lineData={ lineData } focusMode={focusMode} currentDevice={ currentDevice } ticks={ ticks } lineChartXTickFormatter={ lineChartXTickFormatter } /> }
                    { lineDataSegment === 'TENSIONS' && <TensionLineChart lineData={ lineData } currentDevice={ currentDevice } ticks={ ticks } lineChartXTickFormatter={ lineChartXTickFormatter } /> }
                    { lineDataSegment === 'TEMPERATURE' && <TemperatureLineChart lineData={ lineData } currentDevice={ currentDevice } ticks={ ticks } lineChartXTickFormatter={ lineChartXTickFormatter } /> }
                    { lineDataSegment === 'DROPOUT' && <DropoutLineChart lineData={ lineData } currentDevice={ currentDevice } ticks={ ticks } lineChartXTickFormatter={ lineChartXTickFormatter } /> }
                  </>
                 )
            )}
            <TimeSelector
              set_period={setLineDataPeriod}
              excludedPeriods={["month", "year"]}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(DeviceDashboard);
